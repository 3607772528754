import React, { Fragment,Component,useEffect} from 'react';
import '../../App.css';
import Footer from '../Footer';
import HeroSection from '../HeroSection';
import Products from '../Products';

function Home({flag}) {
  useEffect(() => {
    document.title = "Home Smart Home";
  })
  
  return (
    <>
      <HeroSection flag={flag}/>
      <Products flag={flag}/>
      <Footer flag={flag}/>
    </>
  );
}

export default Home;
