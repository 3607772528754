import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import { useTranslation ,withTranslation} from 'react-i18next';
function HeroSection({flag}) {
  const [t,i18n]=useTranslation();
  return (
    <div className='hero-container'>
      <video src='/videos/Homeshow.mp4' autoPlay loop muted />
      <h1>{t('HeroSection.Title')}</h1>
      <div className='hero-btns'>
        <div className="Technolgies" style={flag?{'order': '2'}:{}}>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          path='/OurTechnlogies'
        >
         {t('HeroSection.btn_1')}
        </Button>
        </div>
        <div className="Vision" style={flag?{'order': '1'}:{}}>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={console.log('hey')}
          path='/AboutUs#jumpin'
        >
           {t('HeroSection.btn_2')}
          </Button>
          </div>
      </div>
    </div>
  );
}

export default withTranslation()(HeroSection);
