import './Services.css';
import React, { useState } from 'react'
import './OurTechnlogies.css'
import ImgTitle from '../imgTitle';
import SpecialButton from '../SpecialButton';
import Footer from '../Footer';
import { useTranslation ,withTranslation} from 'react-i18next';

export default function OurTechnlogies({flag}) {
    const [t,i18n]=useTranslation();
    return (
    <div>
        <ImgTitle title1={t('ourTech.title')}/>
        <SpecialButton flag={flag} title1={t('ourTech.SubT1')} classname={"conntent1"}/>
            <div id="conntent1">
                <div className="text_data">
                    <p className="text_show_contenet">
                    {t('ourTech.data1')}
                    </p>
                </div>
                <span className="img_in_spanb" style={{'display':'block','clear':'both'
                            ,'height': '0px'
                            ,'padding-top': '200px'
                            ,'border-top-width':'0px'
                            ,'border-bottom-width':'0px'}}>  
                </span>
            </div>
            <SpecialButton flag={flag} title1={t('ourTech.subT2')} classname={"conntent2"}/>
            <div id="conntent2">
                <div className="text_data">
                    <p className="text_show_contenet">
                    {t('ourTech.data')}
                    </p>
                </div>
                <span className="img_in_spanf" style={{'display':'block','clear':'both'
                            ,'height': '0px'
                            ,'padding-top': '200px'
                            ,'border-top-width':'0px'
                            ,'border-bottom-width':'0px'}}>  
                </span>
            </div>
          <Footer/>
      </div>
    );
  }