import React, { useState } from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Services from './components/pages/Services';
import Conntact from './components/pages/ConntactUs';
import AboutUs from './components/pages/aboutUs';
import OurTechnlogies from './components/pages/OurTechnlogies';
import OurOffer from './components/pages/OurOffer'
import { createBrowserHistory } from 'history'
import { useTranslation } from 'react-i18next';
function App() {
  const [t,i18n]=useTranslation();
  const [Click,setClick]=useState(false);
  function clicked(flag) {
    setClick(!flag);
    console.log(Click)
    if(flag==false){
      console.log('he')
      i18n.changeLanguage('he');
    }
    else{
      i18n.changeLanguage('en');
    }
   
  }
  return (
    <>
      <Router>
        <Navbar myfunc={clicked} />
        <Switch>
          <Route exact path='/' component={() => <Home flag={Click} />}/>
          <Route path='/services' component={() => <Services flag={Click}/>} />
          <Route path='/conntact-us' component={() =><Conntact flag={Click} />} />
          <Route path='/AboutUs' component={() => <AboutUs flag={Click} /> } />
          <Route path='/OurTechnlogies' component={()=><OurTechnlogies flag={Click}/>}/>
          <Route path='/OurOffer' component={OurOffer} />

        </Switch>
      </Router>
    </>
  );
}

export default App;
