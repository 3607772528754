import React, { useState } from 'react'
import './SpecialButton.css'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));
export default function SpecialButton({title1,classname,flag}) {
    const [visble,setVisble]=useState(false);
    function clicked() {
        console.log(classname)
        var conntent1 = document.getElementById(classname);
        setVisble(!visble)
        if(visble){
            conntent1.style.display='block'
        }
        if(!visble){
            conntent1.style.display='none'
         }
        console.log(visble);
    }
    return (
            <>
                <Button variant="contained" className="buttonStyle" onClick={clicked}>
                    <div className="center">
                    <div class="left_button_title" style={flag?{textAlign:'right',order:'2'}:{textAlign:"left",order:'1'}} >
                        <h1 className="alignleft">{title1}</h1>
                    </div>
                    <div className="right_button_icon" style={flag?{order:'1',textAlign:"right"}:{textAlign:"right",order:'2'}} >
                        {visble?
                        (<ChevronRightIcon style={flag?{float:'left'}:{float:'right'}} fontSize="large"/>):
                        (<ExpandMoreIcon  style={flag?{float:'left'}:{float:'right'}} fontSize="large"/>)
                        }
                    </div>
                    </div>
                </Button>
            </>
            );
}