import './Services.css';
import React, { useState } from 'react'
import './OurOffer.css'
import ImgTitle from '../imgTitle';
import SpecialButton from '../SpecialButton';
import Footer from '../Footer';

export default function OurOffer() {
    return (
    <div>
        <ImgTitle title1={"Predefined Packages"}/>
        <SpecialButton title1={"Basic Package"} classname={"Basic"}/>
            <div name="Basic">
            <table id="customers">
                <thead>
                    <tr class="w3-light-grey">
                        <th>Item to include</th>
                        <th>Maximum close Item</th>
                        <th>Quantity</th>
                        <th>Purpose</th>
                    </tr>
                </thead>
                <tr>
                <td>Window Shutter </td>
                <td>1 </td>
                <td>3 </td>
                <td>Open and close shutter </td>
                </tr>
                <tr>
                <td>Light switch </td>
                <td>2 </td>
                <td>4</td>
                <td>Switch on and off lights </td>
                </tr>
                <tr>
                <td>Hot water Tank </td>
                <td> </td>
                <td>1</td>
                <td>Enable remote control or schedule for Hot water on winter </td>
                </tr>
                <tr>
                <td>Mesh(WIFI enhancer) </td>
                <td> </td>
                <td>2</td>
                <td>Enable multiple control and conditioning on devices  </td>
                </tr>
                <tr>
                <td>basic Scenes</td>
                <td> </td>
                <td>2</td>
                <td>Enable multiple control and conditioning on devices  </td>
                </tr>
            </table>
            <p>Price 4200 NIS </p>
            </div>
            <SpecialButton title1={"Extended Package"} classname={"Extended"}/>
            <div name="Extended">
            <table id="customers">
                <thead>
                    <tr class="w3-light-grey">
                        <th>Item to include</th>
                        <th>Maximum close Item </th>
                        <th>Quantity </th>
                        <th>Purpose</th>
                    </tr>
                </thead>
                <tr>
                <td>Window Shutter </td>
                <td>1 </td>
                <td>6 </td>
                <td>Open and close shutter </td>
                </tr>
                <tr>
                <td>Light switch </td>
                <td>2 </td>
                <td>10</td>
                <td>Switch on and off lights </td>
                </tr>
                <tr>
                <td>Hot water Tank </td>
                <td> </td>
                <td>1</td>
                <td>Enable remote control or schedule for Hot water on winter </td>
                </tr>
                <tr>
                <td>Mesh(WIFI enhancer) </td>
                <td> </td>
                <td>3</td>
                <td>Enable multiple control and conditioning on devices  </td>
                </tr>
                <tr>
                <td>basic Scenes</td>
                <td> </td>
                <td>3</td>
                <td>Enable multiple control and conditioning on devices  </td>
                </tr>
                <tr>
                <td>Door Sensor </td>
                <td> </td>
                <td>1</td>
                <td>Enable triggering of scenes opening and closing main door  </td>
                </tr>
                 <tr>
                <td>Temperature Sensor </td>
                <td> </td>
                <td>1</td>
                <td>Captures temperature in a view to trigger scenes </td>
                </tr>
                <tr>
                <td>Sensibo</td>
                <td> </td>
                <td>1</td>
                <td>Interfaces with air conditioning</td>
                </tr>
            </table>
            <p>Price 11000 NIS </p>
            </div>
            <SpecialButton title1={"Media Package basic"} classname={"Media"}/>
            <div name="Media">
            <table id="customers">
                <thead>
                    <tr class="w3-light-grey">
                        <th>Item to include</th>
                        <th>Maximum close Item </th>
                        <th>Quantity </th>
                        <th>Purpose</th>
                    </tr>
                </thead>
                <tr>
                <td>MiBOX</td>
                <td>1 </td>
                <td>1 </td>
                <td>Media player  Netflix Smart IPTV Kody</td>
                </tr>
                <tr>
                <td>Google home  </td>
                <td>1</td>
                <td>1</td>
                <td>Voice c compliant ontrol on any device  </td>
                </tr>
            </table>
            <p>Price 1000 NIS </p>
            </div>
            <SpecialButton title1={"Customized Package"} classname={"Customized"}/>
            <div id="Customized">
                <p>Needs an audit that is billable but will not be charged if customer order a package 
                    Audit price 500 NIS
                 </p>
            </div>
          <Footer/>
      </div>
    );
  }