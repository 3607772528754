import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
function Navbar({myfunc}) {
  const [t,i18n]=useTranslation();
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const [icon,setIcon]=useState(false);
  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };
  function onClick() {
    setIcon(!icon)
    closeMobileMenu();
    myfunc(icon);

  }
  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' rel="icon" className='navbar-logo' onClick={closeMobileMenu} type="image/x-icon" href='/images/icon.ico'>
            <div className="iconWarper">
               <img src="/images/logoimage_sm.jpg"/>
            </div>
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link
                onClick={onClick}
                className='nav-links'
              >
            <div className="iconWarper">
              {icon?
               (<img src="/images/usAichon.png"/>):
               (<img src="/images/israelImg_60x30.png"/>)
              }
            </div>
            </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/AboutUs'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                {t('nav_bar.about_us')}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/services'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                  {t('nav_bar.Services')}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/OurTechnlogies'
                className='nav-links'
                onClick={closeMobileMenu}
                 >
                {t('nav_bar.Technologies')}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/OurOffer'
                className='nav-links'
                onClick={closeMobileMenu}
              >
               {t('nav_bar.Offer')}
              </Link>
            </li>

          </ul>
          {button && <Button buttonStyle='btn--outline' path="/conntact-us"> {t('nav_bar.Contact_us')}</Button>}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
