import styled from 'styled-components'
export const ServicesContainer =styled.div`
height: 800px;
display: flex;
flex-direction: column;
justify-content:center;
align-items: center;
background:black;
background-image: url('/images/img-1.jpg');
background-size: cover;
background-repeat: no-repeat;
@media screen and (max-width: 760px){
    height: 1180;
}
@media screen and(max--width: 480px)
{
    height:1300px;
}
`
export const ServicesWrapper=styled.div`
margin:0 auto;
display: grid;
grid-template-columns:1fr 1fr 1fr;
grid-items: center;
grid-gap: 20px;
padding: 0 50px;
@media screen and (max-width: 760px){
    grid-template-columns:1fr 1fr;
    padding: 0 20px;
}
`
export const ServicesCard=styled.div`
background:#fff;
display:flex
flex-direction: column;
justify-content: flex-start;
align-items:center;
border-radius:10px;
padding:10px;
height:270px;
width: 250px;
box-shadow:0 1px 3px rgba(0,0,0,0.2);
transition: all 0.2s ease-in-out;
&:hover {
    transform: scale(1.02);
    cursor:pointer;
}

`
export const ServicesIcon=styled.img`
max-hight: 140px;
max-width:140px;
padding: 10px 0px 0px 0px;
display: flex;
justify-content: center;
margin-bottom:10px;
`
export const ServicesH1=styled.h1`
font-size:2.5rem;
color:#fff;
margin-bottom:64px;


@media screen and (max-width: 480px)
{
    font-size:2rem;
}
`
export const ServicesH2=styled.h2`
font-size:1rem;
margin-bottom:10px;
text-align:center;

`
export const ServicesP =styled.p
`
font-size:1rem;
text-align:center;
`