
import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Footer({flag}) {
  const [t,i18n]=useTranslation();
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
            {t('Footer.Title')}
        </p>
        <div className='input-areas'>
          <form >
            <div style={{display:"flex",display:"-webkit-flex"}}>
            <div style={flag?{'order': '1'}:{'order':'2','margin-right': '10px'}}>
            <input style={!flag?{direction:"rtl"}:{direction:"ltr"}}
              className='footer-input'
              name='email'
              type='email'
              placeholder={t('Footer.emailBox')}
            />
            </div>
            <div style={flag?{'order':'2'}:{'order':'1','margin-right': '10px'}} >
            <Button className="btn--outline" >{t('Footer.btn_subscribe')}
            </Button>
            </div>
            </div>
          </form>
          <p className='footer-subscription-text' style={!flag?{direction:"rtl",float:"right"}:{direction:"ltr"}}>
          {t('Footer.subT')}
        </p>
        </div>
      </section>
      <div class='footer-links'>
        <div className='footer-link-wrapper' style={!flag?{order:"2",direction:'rtl' }:{order:"1"}}>
          <div class='footer-link-items'>
            <h2> {t('Footer.Contact.Title')}</h2>
            <Link to='/AboutUs#jumpin'>{t('Footer.Contact.1')}</Link>
            <Link to='/'>{t('Footer.Contact.2')}</Link>
            <Link to='/'>{t('Footer.Contact.3')}</Link>
          </div>
        </div>
        <div className='footer-link-wrapper' style={!flag?{order:"1"}:{order:"2"}}>
          <div class='footer-link-items'>
            <h2>{t('Footer.Smedia')}</h2>
            <Link to='/'>Instagram</Link>
            <Link to='/'>Facebook</Link>
          </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <small class='website-rights'>powerd by © avi h</small>
          <div class='social-icons'>
            <Link
              class='social-icon-link facebook'
              to='/'
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <Link
              class='social-icon-link instagram'
              to='/'
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            <Link
              class='social-icon-link youtube'
              to='/'
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;