import React, { useState } from 'react'
import './aboutUs.css'
import { makeStyles } from '@material-ui/core/styles';
import Footer from '../Footer';
import SpecialButton from '../SpecialButton';
import { useTranslation ,withTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));
export default function AboutUs({flag}) {
    const [t,i18n]=useTranslation();
    return (
            <div className="pageStyle">
                <SpecialButton flag={flag} title1={t('AboutUs.1.title')} classname={"conntent"}/>
                <div id="conntent">
                    <div className="sow-headline-container">
                        <h1 class="sow-headline">{t('AboutUs.1.subtitle')}</h1>
                    </div>
                    <div className="text_data">
                        <p className="text_show_contenet">
                        {t('AboutUs.1.data')}
                        </p>
                    </div>
                        <span className="img_in_spana" style={{'display':'block','clear':'both'
                            ,'height': '0px'
                            ,'padding-top': '200px'
                            ,'border-top-width':'0px'
                            ,'border-bottom-width':'0px'}}>  
                        </span>
                    <div className="text_data">
                        <div className="list_we_belive">
                            <p style={{'text-align':'left'}}>For years</p>
                            <ul>
                                <li >{t('AboutUs.1.list.1')}</li> 
                                <li> {t('AboutUs.1.list.2')}</li>
                                <li>{t('AboutUs.1.list.3')}</li>
                            </ul>
                        </div>
                    </div>
             </div>
          <div id="jumpin">
                <SpecialButton flag={flag} title1={t('AboutUs.2.1.title')} classname={"conntent1"}/>
                <div id="conntent1">
                    <div className="sow-headline-container">
                        <h1 class="sow-headline">{t('AboutUs.2.1.subtitle')}</h1>
                    </div>
                    <div className="text_data">
                        <p className="text_show_contenet">
                        {t('AboutUs.2.1.data')}
                        </p>
                        <div className="list_in">
                        <ul>
                            <li>{t('AboutUs.2.1.list.1')}</li>
                            <li>{t('AboutUs.2.1.list.2')}</li>
                            <li>{t('AboutUs.2.1.list.3')}</li>
                        </ul>
                        </div>
                    </div>
                    <div className="sow-headline-container">
                        <h1 class="sow-headline">{t('AboutUs.2.2.title')}</h1>
                    </div>
                    <div className="text_data">
                        <p className="text_show_contenet">
                        {t('AboutUs.2.2.data')}
                        </p>
                    </div>
                    <span className="img_in_spand" style={{'display':'block','clear':'both'
                            ,'height': '0px'
                            ,'padding-top': '200px'
                            ,'border-top-width':'0px'
                            ,'border-bottom-width':'0px'}}>  
                        </span>
                    <div className="sow-headline-container">
                        <h1 class="sow-headline">{t('AboutUs.2.2.subtitle')}</h1>
                    </div>
                    <div className="text_data">
                        <h3><u>{t('AboutUs.2.2.subt')}</u></h3>
                        <div className="list_in">
                        <ul>
                            <li>{t('AboutUs.2.2.list.1')}</li>
                            <li>{t('AboutUs.2.2.list.2')} </li>
                            <li>{t('AboutUs.2.2.list.3')}</li> 
                            <li>{t('AboutUs.2.2.list.4')}</li>
                            <li>{t('AboutUs.2.2.list.5')}</li>
                            <li>{t('AboutUs.2.2.list.6')}</li>
                        </ul>
                        </div>
                    </div>
                    <span className="img_in_spanc" style={{'display':'block','clear':'both'
                            ,'height': '0px'
                            ,'padding-top': '200px'
                            ,'border-top-width':'0px'
                            ,'border-bottom-width':'0px'}}>  
                        </span>
                    <div className="text_data">
                        <h3><u>{t('AboutUs.2.3.title')}</u></h3>
                        <div className="list_in">
                        <ul>
                            <li>{t('AboutUs.2.3.list.1')}</li>
                            <li>{t('AboutUs.2.3.list.2')}</li>
                            <li>{t('AboutUs.2.3.list.3')}</li> 
                            <li>{t('AboutUs.2.3.list.4')}</li>
                            <li>{t('AboutUs.2.3.list.5')}</li>
                        </ul>
                        </div>
                    </div>
                    <div className="sow-headline-container">
                        <h1 class="sow-headline">{t('AboutUs.2.3.subtitle')}</h1>
                    </div>
                    <div className="text_data">
                        <p>{t('AboutUs.2.3.data')}</p>
                    </div>

                </div>
             
            </div>
            <Footer/>
        </div>

    )
}
