import React from 'react';
import '../App.css'
import {ServicesContainer,ServicesH1,ServicesWrapper,ServicesCard,ServicesIcon,ServicesH2,ServicesP} from './ServicesElements'
import { Link } from 'react-router-dom';
import { useTranslation ,withTranslation} from 'react-i18next';
export default function Products({flag}) {
  const [t,i18n]=useTranslation();
  return(
      <ServicesContainer id="services">
        <ServicesH1>{t('OurOffer.Title')}</ServicesH1>
          <ServicesWrapper>
            <Link to='/OurOffer#Extended' style={{ color: 'black' ,'text-decoration':'none'}}>
            <ServicesCard>
              <ServicesIcon src='/images/undraw_hologram_fjwp.svg'/>
              <ServicesH2>{t('OurOffer.ExtendCard.Title')}</ServicesH2>
              <ServicesP>{t('OurOffer.ExtendCard.subText')}</ServicesP>
            </ServicesCard>
            </Link>
            <Link to='/OurOffer#Customized' style={{ color: 'black' ,'text-decoration':'none'}}>
            <ServicesCard>
              <ServicesIcon src='/images/undraw_Wishlist_re_m7tv.svg'/>
              <ServicesH2>{t('OurOffer.CustomizeCard.Title')}</ServicesH2>
              <ServicesP>{t('OurOffer.CustomizeCard.subText')}</ServicesP>
            </ServicesCard>
            </Link>
            <Link to='/OurOffer#Media' style={{ color: 'black' ,'text-decoration':'none'}}>
            <ServicesCard>
              <ServicesIcon src='images/undraw_voice_control_ofo1.svg'/>
              <ServicesH2>{t('OurOffer.mediaCard.Title')}</ServicesH2>
              <ServicesP>{t('OurOffer.mediaCard.subText')}</ServicesP>
            </ServicesCard>
            </Link>
            <Link to='/OurOffer#Basic' style={{ color: 'black' ,'text-decoration':'none',  'grid-row': 'span 2 / 1'}}>
            <ServicesCard>
              <ServicesIcon src='/images/undraw_build_your__home_csh6.svg'/>
              <ServicesH2>{t('OurOffer.BasicCard.Title')}</ServicesH2>
              <ServicesP>{t('OurOffer.BasicCard.subText')}</ServicesP>
            </ServicesCard>
            </Link>
          </ServicesWrapper>
       </ServicesContainer> 
    );
}
