import './Services.css';
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Footer from '../Footer';
import SpecialButton from '../SpecialButton';
import ImgTitle from '../imgTitle'
import { useTranslation ,withTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));
function Page({flag}) {
  const [t,i18n]=useTranslation();
    return (
            <div className="pageStyle" >
              <SpecialButton flag={flag} title1={t('Services.Audit.Title')} classname={"conntent1"}/>
                <div id="conntent1">
                    <div className="text_data" style={flag?{'direction':'rtl','textAlign':"right"}:{'direction':'ltr','textAlign':"left"}}>
                        <p className="text_show_contenet">
                       {
                         t('Services.Audit.subText')
                       }
                        </p>
                    </div>
                    <span className="img_in_spanr" style={{'display':'block','clear':'both'
                            ,'height': '0px'
                            ,'padding-top': '200px'
                            ,'border-top-width':'0px'
                            ,'border-bottom-width':'0px'}}>  
                    </span>
                 </div>
                 <SpecialButton flag={flag} title1={t('Services.Installation.Title')} classname={"conntent2"}/>
                <div id="conntent2">
                    <div className="text_data" style={flag?{'direction':'rtl','textAlign':"right"}:{'direction':'ltr','textAlign':"left"}}>
                        <p className="text_show_contenet">
                        {
                          <ul>
                            <li>{t('Services.Installation.subText.1')}</li>
                            <li>{t('Services.Installation.subText.2')}</li>
                            <li>{t('Services.Installation.subText.3')}</li>
                          </ul>
                        }         
                       </p>
                    </div>
                    <span className="img_in_spang" style={{'display':'block','clear':'both'
                            ,'height': '0px'
                            ,'padding-top': '200px'
                            ,'border-top-width':'0px'
                            ,'border-bottom-width':'0px'}}>  
                        </span>
                 </div>
                 <SpecialButton flag={flag} title1={t('Services.Support.Title')} classname={"conntent3"}/>
                <div id="conntent3" >
                    <div className="text_data" style={flag?{'direction':'rtl','textAlign':"right"}:{'direction':'ltr','textAlign':"left"}}>
                      <p>{t('Services.Support.subTitle')}</p>
                        <ol>
                          <li> {t('Services.Support.list.1')}</li>
                          <li> {t('Services.Support.list.2')}</li>
                          <li> {t('Services.Support.list.3')}</li>

                        </ol> 
                    </div>
                    <span className="img_in_spanf" style={{'display':'block','clear':'both'
                            ,'height': '0px'
                            ,'padding-top': '200px'
                            ,'border-top-width':'0px'
                            ,'border-bottom-width':'0px'}}>  
                        </span>
                 </div>
                 <Footer/>

            </div>

    )
}

export default function Services({flag}) {
  const [t,i18n]=useTranslation();
  return (
    <div className="grandfather">
        <ImgTitle title1={t('Services.nameOfPage')}/>
        <Page flag={flag}/>
        
    </div>
  );
}
