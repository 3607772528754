import validator from 'validator' 
export default function validateInfo(values) {
    let errors = {};
  
    if (!values.username.trim()) {
      errors.username = 'Username required';
    }
    // else if (!/^[A-Za-z]+/.test(values.name.trim())) {
    //   errors.name = 'Enter a valid name';
    // }
  
    if (!values.email) {
      errors.email = 'Email required';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Email address is invalid';
    }
    const isValidPhoneNumber = validator.isMobilePhone(values.phone);

    if (!values.phone) {
      errors.phone = 'Password is required';
    } else if (isValidPhoneNumber) {
      errors.phone = 'phone not correcrt';
    }
 
    return errors;
  }