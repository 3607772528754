import React, { useState } from 'react'
import './imgTitle.css'
export default function ImgTitle({title1}) {
return(
        <div className className="parent" >
            <div className="son_of_a_bich" style={{width:'100%',height:'100%'}}> 
                <span><h1>{title1}</h1></span>
            </div>
        </div>
);
}